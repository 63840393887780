const DynamicName = process.env.VUE_APP_COMPANY_NAME;
const Train_321 = {
    'SITE_NAME': 'Train 321',
    'SITE_URL': 'www.train321.com',
    'LOGO': 'Train_321.png',
    'INFO_EMAIL': 'info@train321.com',
    'SUPPORT_EMAIL': 'support@train321.com'
}
 const Home_of_Training = {
    'SITE_NAME': 'Home Of Training',
    'SITE_URL': 'www.homeoftraining.com',
    'LOGO': 'Homeoftraining.png',
    'INFO_EMAIL': 'info@homeoftraining.com',
    'SUPPORT_EMAIL': 'support@homeoftraining.com'
}
const CRA_Train_321= {
    'SITE_NAME': 'California Restaurant Association',
    'FOOTER_NOTICE': 'Train 321, LLC',
    'SITE_URL': 'https://cra.train321.com',
    'LOGO': 'cra_logo.png',
    'INFO_EMAIL': 'cra@train321.com',
    'SUPPORT_EMAIL': 'support@train321.com'
}
const DRA_Train_321 = {
	'SITE_NAME': 'Delware Restaurant Association',
	'FOOTER_NOTICE': 'Train 321, LLC',
	'SITE_URL': 'https://dra.train321.com',
	'LOGO': 'dra_logo.png',
	'INFO_EMAIL': 'dra@train321.com',
	'SUPPORT_EMAIL': 'support@train321.com'
}
const Origin_Specialist = {
	'SITE_NAME': 'Origin Speciallist',
	'FOOTER_NOTICE': 'Origin Specialist, LLC',
	'SITE_URL': 'www.train321.com',
	'LOGO': 'origin.png',
	'INFO_EMAIL': 'origin@train321.com',
	'SUPPORT_EMAIL': 'support@train321.com'
}
let Dynamic = {};
switch (DynamicName) { 
    case "Train_321":
        Dynamic[DynamicName] = Train_321;
        break;
    case "CRA_Train_321":
        Dynamic[DynamicName] = CRA_Train_321;
        break;
    case "DRA_Train_321":
		Dynamic[DynamicName] = DRA_Train_321;
		break;
    case "Home_of_Training":
        Dynamic[DynamicName] = Home_of_Training;
        break;
    default:
       Dynamic["Train_321"] = Train_321;  
}
let DName = Dynamic[DynamicName];
export  { DName as Dynamic }  ;
